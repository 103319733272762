.App {
  padding: 30px;
}

.fade.show.popover.bs-popover-bottom {
  z-index: 9999999;
}

.error-msg {
  font-size: 0.7rem;
  text-align: center;
  margin-top: 10px;
}

.ncameo-plugin-header .container {
  min-width: 300px;
}
.ncameo-plugin-header {
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 99;
}

.rx-soft-phone .removecolorbut,
.rx-soft-phone .removecolorbut:hover,
.rx-soft-phone .removecolorbut:active,
.rx-soft-phone .removecolorbut:focus {
  border: none;
  background: none;
  background-color: #fff0 !important;
  color: #000;
  padding: 0;
  box-shadow: none;
  outline: none;
}
.rx-soft-phone .removecolorbut img {
  width: 50px;
  cursor: pointer;
}

.rx-soft-phone .calllistbox {
  border: 0px solid black;
  width: 360px;
  min-height: 43rem;
  max-height: 43rem;
  height: auto;
  position: absolute;
  box-shadow: 0px 0px 6px 2px #dadada;
  margin-top: 0;
  padding: 0px;
  background: #fff;
  border-radius: 0px 10px 10px 0px;
  text-align: center;
  right: 20px;
  bottom: 20px;
  display: inline-flex;
  z-index: 9999;
}
.calllistbox ul {
  list-style: none;
  padding: 0;
}
#popover-basic ul li {
  float: left;
  width: 100%;
  margin: 0px 0;
  list-style: none;
  border-bottom: 1px solid rgb(234, 234, 234);
  padding: 8px 8px;
  display: inline-flex;
  cursor: pointer;
}
#popover-basic ul li img {
  width: 18px;
  margin-right: 5px;
}
#popover-basic ul li:hover {
  background: #f2f2f2;
}
#popover-basic ul li:last-child {
  border-bottom: none;
}
.rx-soft-phone
  button.removecolorbut.maincallcalllist.btn.btn-primary
  img.firstmaincallicon {
  box-shadow: 0px 0px 8px rgb(202 202 202);
  border-radius: 50px;
  border: 0px solid #fff;
  background: #0f0f0f;
  padding: 10px;
}
.rx-soft-phone
  button.removecolorbut.maincallcalllist.btn.btn-primary
  .firstmaincallicon:hover {
  box-shadow: 0px 0px 8px rgb(159, 159, 159);
}
.rx-soft-phone
  button.removecolorbut.maincallcalllist.btn.btn-primary
  img.firstmaincallicon.logingicon {
  width: 50px !important;
  height: 50px;
  text-align: center;
  margin: 0px auto 0;
  float: none;
  padding: 10px;
}
.rx-soft-phone .maincallicon {
  float: left;
  width: 100%;
}
.rx-soft-phone button.removecolorbut.maincallcalllist.btn.btn-primary {
  position: absolute;
  bottom: 40px;
  right: 40px;
}
.rx-soft-phone .popupboxclose {
  float: right;
  font-size: 20px;
  color: #313131;
  cursor: pointer;
  line-height: 22px;
}

.rx-soft-phone .logingicon {
  width: 24px !important;
  height: 24px;
  text-align: center;
  margin: 20px auto 0;
  float: none;
}
.rx-soft-phone .dialpad-border-right img {
  width: 18px;
  margin: 7px auto;
  float: none;
  color: #313131;
}
.rx-soft-phone .dialpad-border-right {
  border-right: 1px solid #f3f7fb;
  text-align: center;
  padding: 3px;
  position: absolute;
  left: -56px;
  background: #000;
  border-radius: 10px 0px 0px 10px;
  box-shadow: -4px 0px 7px 2px #dadada;
  width: 56px;
}

.rx-soft-phone .calllistbox .dialpad-border-right ul li {
  margin: 10px auto;
  border-radius: 10px;
  width: 40px;
  height: 40px;
  text-align: center;
  padding: 4px;
  cursor: pointer;
  position: relative;
}
.rx-soft-phone .calllistbox .dialpad-border-right ul li:hover,
.rx-soft-phone .calllistbox .dialpad-border-right ul li.active {
  box-shadow: none;
  background: #666666;
}
.rx-soft-phone .mainpopupbox {
  padding: 0;
  position: relative;
  height: 100%;
  width: 100%;
  float: left;
}
.rx-soft-phone button.removecolorbut.smallcallicon.btn.btn-primary {
  margin: 0 4px;
}
.rx-soft-phone button.removecolorbut.btn.btn-primary:focus {
  box-shadow: none;
  border: none;
  background: none;
  background-color: #fff0 !important;
  padding: 0;
  outline: none;
}
#popover-basic {
  background: #fff;
  box-shadow: 0px 3px 10px rgb(202, 202, 202);
  float: left;
  padding: 0;
  position: relative;
  margin-top: 7px;
  border-radius: 5px;
  display: flex;
  right: 0rem;
}
#popover-basic {
  border: none;
  width: 17rem;
  margin-right: 10px;
}
#popover-basic ul {
  margin: 0;
  width: 100%;
  float: left;
  padding: 0;
  list-style: none;
}
#popover-basic .popover-body {
  padding: 10px !important;
  width: 100%;
  float: left;
}
.rx-soft-phone .mainfullbox {
  width: 100%;
  float: left;
  position: relative;
}
.rx-soft-phone .mainboxheader {
  width: 100%;
  background: #f7f7f7;
  float: left;
  padding: 12px 10px 8px;
  margin-bottom: 5px;
  border-radius: 0px 10px 0 0px;
}
.rx-soft-phone button.removecolorbut.plusicon.btn.btn-primary {
  position: absolute;
  bottom: 20px;
  right: 20px;
  z-index: 99;
}
.rx-soft-phone button.removecolorbut.plusicon.btn.btn-primary p {
  background: #000;
  width: 50px;
  height: 50px;
  border: 0px solid #ccc;
  font-size: 24px;
  border-radius: 50px;
  color: #ffffff;
  float: right;
  padding: 5px;
}

@media (min-width: 576px) {
  .rx-soft-phone .container,
  .rx-soft-phone .container-sm {
    max-width: none;
  }
}

.initialErrorMsgContainer p {
  margin: 1rem;
}

.actionButtonContainer img {
  width: 25px;
  cursor: pointer;
  box-shadow: 0px 0px 8px rgb(202 202 202);
  border-radius: 50px;
  border: 0px solid #fff;
}
